<template>
  <div class="mytask-detail" v-wechat-title="$route.meta.title">
    <div class="mytask-detail-wrapper">
      <div class="mytask-detail-content">
        <div class="task-info-part flex-layout">
          <img class="cover" :src="downloadURL + info.themeCover" :onerror="$store.state.course.defaultTaskBigCover" />
          <div class="task-info">
            <p class="task-title">{{ info.themeName }}</p>
            <p class="other-info">所属项目：{{ info.projectName || '暂无' }}</p>
            <p class="other-info">相关课程：{{ info.courseName || '暂无' }}</p>
            <p class="other-info">所属学科：{{ info.subjectName || '暂无' }}</p>
            <p class="other-info">主题类型：{{ themeTypeMap[info.themeType] || '暂无' }}</p>
            <p class="other-info">
              <span style="margin-right:25px;">作业共计：{{ info.questCount }}个</span>
              <span>已完成：{{ (info.questCount - info.undone) || 0 }}个</span>
            </p>
          </div>
        </div>
        <div class="task-list-part" v-show="questionlsit && questionlsit.length > 0">
          <ul class="list">
            <li class="list-item flex-align-between" v-for="(item, index) in questionlsit" :key="index">
              <div class="flex-layout">
                <p class="item-name wes">{{ item.title.workname }}</p>
                <p class="item-time">
                  <span>{{ item.title.beginTime.replace(/-(?=[^-]*$)/, ' ')}} - {{ item.title.endTime.replace(/-(?=[^-]*$)/, ' ') }}</span>
                  <i class="status doing">
                    {{ item.status == 1 ? "未开始" : item.status == 2 ? "进行中" : item.status == 3 ? "已结束" : "" }}
                  </i>
                  <i style="margin-left:10px" class="status doing" v-if="item.hasSupplement">
                    已补交
                  </i>
                </p>
              </div>
              <div>
                <button class="go-do-task csp" style="margin-right:10px" @click="goAI" v-if="item.id == 2496 || item.id == 2470">
                  课堂观察
                </button>
                <button class="go-do-task csp" :class="{ 'noStart': item.btnStatus == 4 || !item.finishStudy }"
                  @click="checkWork(item.id, item.type, item.hasSupplement ? 3 : item.btnStatus, '', '', item.btnStatus, item.finishStudy)">
                  {{ item.btnStatus == 1 ? "查看" : item.btnStatus == 2 ? "去完成" : item.btnStatus == 3 ? "查看" : item.btnStatus ==
                    4 ? "查看" : "已完成" }}
                </button>
                <button style="margin-left:10px" class="go-do-task csp"
                  :class="{ 'noStart': item.isBegin || item.btnStatus == 4 }"
                  @click="checkWork(item.id, item.type, 2, true, item.isBegin ? true : false, item.btnStatus, item.finishStudy)"
                  v-if="item.showSubAgain && !item.hasSupplement">
                  补交
                </button>
              </div>
            </li>
          </ul>
          <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
            :limit.sync="pages.pageSize" :pageSizes="[5, 10, 15, 20]" @pagination="handlePageChange">
          </pagination>
        </div>
        <Empty :show="questionlsit && questionlsit.length <= 0" :text="'暂无作业'"></Empty>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      questionlsit: [],
      pages: { //分页
        currentPage: 1,
        pageSize: 5,
        total: 0,
      },
      themeTypeMap: {
        1: "作业",
        2: "测试",
        3: "问卷"
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    // 翻页
    handlePageChange(data) {
      this.pages.currentPage = data.pageIndex
      this.pages.pageSize = data.pageSize
      this.checkWorkList();
    },
    checkWork(workid, type, status, isSubAgain, isBegin, btnStatus, finished) {
      console.log(btnStatus);
      if (status == 1) {
        // console.log("查看模式,跳转作业详情,无答案、不可编辑");
        if (!finished) {
          this.$message({
            message: '需要看完课程后才能作答',
            type: 'warning'
          });
        } else {
          this.$router.push({
            path: "/view_empty",
            query: {
              questionId: workid,
              breadNumber: 5,
              // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
              // userType: "student",
              // type: 'task',
              // from: 'task',
              // prevId: this.$route.params.id,
              // query: JSON.stringify(this.$route.query)
            }
          })
        }
      } else if (status == 2) {
        // console.log("去完成,跳转答题页面,无答案、可编辑");
        if (type === 2 || type === 3) {
          // console.log("测试&问卷");
          this.$router.push({
            path: "/confirm",
            query: {
              questionId: workid,
              breadNumber: 5,
              // userType: "student",
              // status,
              // type: 'task',
              theme: this.$route.query.theme
            }
          });
        } else {
          // 判断是否需要看完课程才能提交
          if (!finished) {
            this.$message({
              message: '需要看完课程后才能作答',
              type: 'warning'
            });
            return
          }
          if (isSubAgain && isBegin) {
            this.$message({
              message: '补交时间未到',
              type: 'warning'
            });
          } else {
            this.$router.push({
              path: "/answer",
              query: {
                questionId: workid,
                // userType: "student",
                // status,
                // type: 'task',
                from: 'task',
                // prevId: this.$route.params.id,
                // query: JSON.stringify(this.$route.query)
                isSubAgain
              }
            });
          }

        }
      } else {
        // console.log("跳转批改详情,批改后");
        if (!finished) {
          this.$message({
            message: '需要看完课程后才能作答',
            type: 'warning'
          });
        } else {
          this.$router.push({
            path: "/view",
            query: {
              questionId: workid,
              breadNumber: 5,
              // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
              // userType: "student",
              // type: 'task',
              // from: 'task',
              type
              // prevId: this.$route.params.id,
              // query: JSON.stringify(this.$route.query)
            }
          });
        }
      }
    },
    getTaskInfo() {
      this.$Api.Form.getTaskInfo(this.$route.params.id)
        .then(value => {
          // console.log("11111",value)
          this.info = value.data;
          this.$route.meta.title = this.info.themeName;
          this.$store.commit('breadPageNameChange', this.info.themeName);
          this.checkWorkList();
          // console.log(this.info);
        })
        .catch(reason => {
          console.log(reason);
        });
    },
    //查询当前课程下的作业列表
    checkWorkList() {
      let params = {
        page: this.pages.currentPage,
        limit: this.pages.pageSize,
        // projectId:46,
        // bindingId: this.$route.query.bindingId,
        bindingId: this.info.bindingId,
        id: this.$route.params.id,
      }
      //查询当前主题下的所有作业列表
      this.$Api.Form.getWorkList(params)
        .then(value => {
          //  console.log(value);
          this.questionlsit = value.data.list;
          this.pages.total = value.data.totalCount;
          let currentTime = new Date().getTime();
          console.log('当前问卷列表---', this.questionlsit)
          console.log('当前时间戳', currentTime)
          this.questionlsit.map(item => {
            // console.log('每套问卷的开始时间--', new Date(item.startRestriction).getTime())
            item.title = JSON.parse(item.title);
            item.startRestriction = new Date(item.startRestriction.replace(/-/g, '/')).getTime()
            item.endRestriction = new Date(item.endRestriction.replace(/-/g, '/')).getTime();
            /**
             * 作业状态
             *  未开始 1 -- 当前时间 < 作业开始时间
             *  进行中 2 -- 当前时间 > 作业开始时间 && 当前时间 < 作业结束时间
             *  已结束 3 -- 当前时间 > 作业结束时间
             */
            item.status = currentTime < item.startRestriction ? 1 : currentTime > item.startRestriction &&
              currentTime < item.endRestriction ? 2 : 3
            /**
             * 作业按钮状态
             *  查看 1 -- ①作业状态未开始 ②作业状态已结束&&学员未完成
             *  去完成 2 -- 作业状态进行中&&学员未完成
             *  已完成 3 -- ①作业状态进行中&&学员已完成 ②作业状态已结束&&学员已完成
             *  查看(置灰) 4 --  ①作业状态未开始 ②作业状态已结束&&学员未完成 ③作业勾选看课后才能提交，且未完成看课
             */
            if (item.status == 1) {
              // 判断是否需要置灰
              if (item.title.needStudy && !item.finishStudy) {
                item.btnStatus = 4
              } else {
                item.btnStatus = 1
              }
            } else if (item.status == 3 && item.isAnswer == 0) {
              // 判断是否需要置灰
              if (item.title.needStudy && !item.finishStudy) {
                item.btnStatus = 4
              } else {
                item.btnStatus = 1
              }
            } else if (item.status == 2 && item.isAnswer == 0) {
              // 判断是否需要置灰
              if (item.title.needStudy && !item.finishStudy) {
                item.btnStatus = 4
              } else {
                item.btnStatus = 2
              }
            } else if (item.status == 2 && item.isAnswer == 1) {
              item.btnStatus = 3
            } else if (item.status == 3 && item.isAnswer == 1) {
              item.btnStatus = 3
            }
            // 是否显示补交按钮
            if (item.title.subagain) {
              // 有补交,判断是否在补交时间段内
              let beginTime = item.title.subStart.split("");
              let endTime = item.title.subEnd.split("");
              beginTime.splice(10, 1, " ")
              endTime.splice(10, 1, " ");
              if (currentTime > new Date(endTime.join("")).getTime()) {
                // 补交时间已结束
                console.log('补交时间已结束')
                item.showSubAgain = false;
                return false
              }
              if (currentTime < new Date(beginTime.join("")).getTime()) {
                // 补交时间未开始
                console.log('补交时间未开始')
                item.showSubAgain = true;
                item.isBegin = true
                return false
              }
              if (item.isAnswer == 1) {
                // 已完成用户不显示补交按钮
                console.log('已完成用户不显示补交按钮')
                item.showSubAgain = false;
                return false
              }
              item.showSubAgain = true
            } else {
              // 无补交
              console.log('该作业无补交')
              item.showSubAgain = false
            }
          });
          console.log("当前问卷列表", this.questionlsit);
        })
        .catch(reason => {
          console.log(reason);
        });
    },
    goAI(){
        window.open('https://jm.cnuen.net/courseAI')
        // window.open('https://localhost:8080/courseAI')
        // this.$router.push('/courseAI')
    }
  },
  watch: {},
  mounted() {
    // this.checkWorkList();
    this.getTaskInfo();
    console.log('dayjs:',this.dayjs);
    console.log('测试dayjs:',this.dayjs('2024-07-23-00:00:001').format('YYYY-MM-DD HH:mm') )
  },
  created() { },
  deactivated() { },
};
</script>

<style lang="less" scoped>
.mytask-detail {
  // min-height: 100vh;

  .mytask-detail-wrapper {
    // flex: 1;
    width: 990px;
    margin: 0 auto;
  }

  .bread-crumb {
    line-height: 80px;
  }

  .task-info-part {
    padding: 21px 0 21px 26px;
    background-color: #fff;
    border: 1px solid #F2F2F2;
    border-radius: 8px;

    .cover {
      width: 376px;
      height: 212px;
      margin-right: 26px;
      border-radius: 6px;
    }

    .task-title {
      margin: 12px 0 20px;
      font-size: 16px;
      font-weight: bold;
    }

    p.other-info {
      font-size: 12px;
      color: #999;
      line-height: 30px;
    }
  }

  .task-list-part {
    margin: 31px 0 100px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 35px 32px 10px 25px;
    background-color: #fff;

    .list {
      overflow: hidden;
    }

    .list-item {
      background-color: #FBFAFA;
      border-radius: 4px;
      line-height: 50px;
      padding: 0 38px 0 21px;
      margin-bottom: 14px;
    }

    .item-name {
      margin-right: 42px;
      width: 320px;
      font-size: 13px;
    }

    .item-time {
      font-size: 12px;
      color: #999;
    }

    .status {
      border: 1px solid #999999;
      border-radius: 25px;
      font-size: 12px;
      color: #999;
      padding: 0 8px;
      line-height: 20px;
      margin-left: 25px;

      &.doing {
        border: 1px solid #508EF9;
        color: #508EF9;
      }
    }

    .go-do-task {
      border: 1px solid #508EF9;
      border-radius: 25px;
      font-size: 12px;
      color: #508EF9;
      padding: 0 22px;
      line-height: 24px;

      &:hover {
        background-color: #508EF9;
        color: #fff;
      }
    }

    .noStart {
      border: 1px solid #C0C4CC;
      border-radius: 25px;
      font-size: 12px;
      color: #C0C4CC;
      padding: 0 22px;
      line-height: 24px;

      &:hover {
        background-color: #C0C4CC;
        color: #fff;
      }
    }
  }


  @media screen and (min-width:1250px) {
    .mytask-detail-wrapper {
      width: 1200px;
    }

    .task-info-part {
      padding: 26px 0 26px 32px;

      .cover {
        width: 456px;
        height: 257px;
        margin-right: 32px;
      }

      .task-title {
        margin: 15px 0 25px;
        font-size: 19px;
      }

      p.other-info {
        font-size: 14px;
        line-height: 35px;
      }
    }

    .task-list-part {
      margin: 38px 0 100px;
      padding: 42px 39px 15px 30px;

      .list-item {
        line-height: 60px;
        padding: 0 46px 0 26px;
        margin-bottom: 17px;
      }

      .item-name {
        font-size: 16px;
      }

      .go-do-task {
        font-size: 12px;
        padding: 0 27px;
        line-height: 29px;
      }


      .noStart {
        border: 1px solid #C0C4CC;
        border-radius: 25px;
        font-size: 13px;
        color: #C0C4CC;
        padding: 0 27px;
        line-height: 29px;

        &:hover {
          background-color: #C0C4CC;
          color: #fff;
        }
      }
    }
  }
}
</style>